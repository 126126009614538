<script setup lang="ts">
import { ParagraphShopTeasers } from '~/api/graphql/generated'
import { Product } from '@shopware-pwa/types'

const props = defineProps<{
  entity: ParagraphShopTeasers
}>()

const link = computed(() => ({
  url: props.entity.fieldLink?.url?.path ?? '',
  text: props.entity.fieldLink?.title ?? '',
  target: props.entity.fieldLink?.options.attributes?.target ?? '',
}))

const ids = computed(
  () =>
    props.entity.fieldProduct?.map((product) => {
      const matches = product?.match(/.*\((?<productId>[0-9a-z]{32})\)/) as RegExpMatchArray | null

      if (matches !== null && matches.groups) {
        return matches.groups.productId
      }

      return ''
    }) || [],
)

const { search } = useProductSearch()

const { data } = await useAsyncData(`paragraph shop teasers ${props.entity.id}`, async () => {
  const products = await Promise.allSettled(
    ids.value.map(async (id) => {
      const response = await search(id, {
        withCmsAssociations: false,
        criteria: {
          associations: {
            properties: {},
          },
        },
      })

      if (!response) return null
      return response?.product
    }),
  )
  return products
})

const products = data.value?.filter((res) => res.status === 'fulfilled')?.map((res: any) => res.value as Product) || []
</script>

<template>
  <Section v-animate="{ animation: 'vertical-reveal' }">
    <SectionTitle :title="entity.fieldTitle" :sub-title="entity.fieldDescription?.processed" />
    <Products v-if="products.length" :products="products" />
    <Button v-if="link?.url" secondary icon="offers" :to="link.url">{{ link.text }}</Button>
  </Section>
</template>
<style lang="scss" scoped>
.button.secondary {
  @include fluid(margin-top, 48px, 64px);
  @include breakpoint(ts) {
    align-self: end;
    justify-self: start;
  }
}
</style>
